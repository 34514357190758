import { graphql } from 'gatsby';
import React from 'react';

import { ShowreelVideo } from '../components/Home/ShowreelVideo';
import { Root } from '../components/Root';

export const query = graphql`
  {
    sanityShowreelVideo {
      VideoUrl
    }
  }
`;
export default ({ data }) => {
  const videoUrl: string = data.sanityShowreelVideo.VideoUrl;
  return (
    <Root includeNavbar={false} includeFooter={false}>
      <ShowreelVideo videoUrl={videoUrl} />
    </Root>
  );
};
