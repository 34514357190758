import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import Vimeo from '@u-wave/react-vimeo';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { UnstyledLink } from '../UI/StyledLink';
import { Text } from '../UI/Text';

type Props = {
  videoUrl: string;
};

export function ShowreelVideo({ videoUrl }: Props) {
  const isVimeo = videoUrl.includes('vimeo');
  const isYoutube = videoUrl.includes('youtube');

  const youtubeUrlId = isYoutube
    ? `https://youtube.com/embed/${videoUrl.split('v=')[1]}?autoplay=1`
    : null;

  return (
    <ShowreelContainer>
      <BackContainer to="/">
        <Back mode="buttonSecondary">X</Back>
      </BackContainer>
      <Container>
        {isVimeo && (
          <Vimeo video={videoUrl} autoplay={true} responsive={true} />
        )}
        {isYoutube && (
          <YoutubeIframe
            src={youtubeUrlId as string}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameBorder={0}
          />
        )}
      </Container>
    </ShowreelContainer>
  );
}

const ShowreelContainer = styled.div`
  background: black;
  height: 100vh;
`;

const BackContainer = styled(UnstyledLink)`
  ${forBreakpoint(
    Breakpoint.Phone,
    css`
      margin: 1rem;
    `
  )};
  ${forBreakpoint(
    Breakpoint.Wide,
    css`
      margin: 3rem;
    `
  )};
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin: 4rem;
    `
  )};
`;

const Back = styled(Text)`
  color: white;
  ${forBreakpoint(
    Breakpoint.Desktop,
    css`
      font-size: 2rem;
    `
  )};

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      font-size: 3rem;
    `
  )};
`;

const Container = styled.div`
  ${forBreakpoint(
    Breakpoint.Phone,
    css`
      height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      vertical-align: middle;
    `
  )};
  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      height: 85vh;
    `
  )};
`;

const YoutubeIframe = styled.iframe`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
`;
